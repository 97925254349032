import { getDoc } from 'firebase/firestore';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import { useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { useClosestSettings } from 'src/hooks/useClosestSettings';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { useSessionDataParser } from 'src/workers/dataWorkerApi';
import SessionDataReviewer from './Review/SessionDataReviewer';

const SessionReview: React.FC = () => {
  const { domain, session } = useParams<SessionMatchParams>();

  // Getting user profile and settings
  const { profile, mostRecentSettings, company } = useAppSelector((state) => ({
    company: selectTargetCompany(state),
    profile: selectCurrentUserProfile(state),
    mostRecentSettings: selectSettings(state, domain)
  }));

  // Getting session data
  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    profile && buildDocumentRef(getSessionDoc(company!, domain, session))
  );

  const [, settings] = useClosestSettings(
    company,
    domain,
    sessionData?.startTime,
    mostRecentSettings
  );

  const wasTask = Boolean(sessionData?.wasTask);

  // Using parser to inflate the addresses
  const { isWorking, rowsAndColumnsOrError } = useSessionDataParser(
    sessionData,
    settings,
    wasTask,
    false
  );

  // Getting the fields that are going to be checked
  const fields = useMemo(() => {
    const checkFields: { fieldName: string; isRequired: boolean }[] = [];
    let resultFieldNull: string = '';

    if (settings != null) {
      // Getting Data Fields
      Object.values(settings.processFlow.labelDesigns).forEach((ld) =>
        ld.barcodePatterns.forEach((bp) => {
          bp.dataFields.forEach((df) => {
            if (df.type == 'data') {
              checkFields.push({
                fieldName: df.name,
                isRequired: bp.isRequired
              });
            }
          });
        })
      );

      // Getting Manual Input Fields
      Object.values(settings.processFlow.manualInputFields).forEach((mif) =>
        checkFields.push({ fieldName: mif.field, isRequired: mif.mandatory })
      );

      // Getting Result Fields
      resultFieldNull = settings.fieldSettings.resultFields.null;
    }

    return {
      checkFields: checkFields.filter((field, index) => checkFields.indexOf(field) === index),

      resultFieldNull
    };
  }, [settings]);

  const [totalAddresses, setTotalAddresses] = useState<{}[]>([]);
  const [reviewableAddresses, setReviewableAddresses] = useState<{}[]>([]);

  // Filtering which addresses are going to be reviewed
  const getReviewableAddresses = async () => {
    if (!profile || !company) return;

    const docRef = buildDocumentRef(getSessionDoc(company, domain, session));
    const docSnap = await getDoc(docRef);

    const _reviewableAddresses = docSnap.data()?.reviewableAddresses;

    setTotalAddresses(rowsAndColumnsOrError?.['rows']);

    if (reviewableAddresses.length) {
      setReviewableAddresses([]);
    }

    rowsAndColumnsOrError?.['rows'].map((row: {}) => {
      _reviewableAddresses?.map((address) => {
        if (row['address'] === address['address']) {
          setReviewableAddresses((old) => [...old, row]);
        }
      });
    });
  };

  useEffect(() => {
    getReviewableAddresses();
  }, [rowsAndColumnsOrError]);

  return (
    <SessionDataReviewer
      reviewableAddresses={reviewableAddresses}
      totalAddresses={totalAddresses}
      checkFields={fields.checkFields}
      settings={settings}
      resultFieldNull={fields.resultFieldNull}
    />
  );
};

export default SessionReview;
