import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useParams } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { useIntl } from 'react-intl';

const useStyles = appMakeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    maxHeight: '375px'
  },
  headerCell: {
    fontWeight: 'bold'
  },
  alteredValue: {
    color: green[500],
    fontSize: 'inherit',
    fontFamily: 'inherit'
  }
}));

type ReviewedData = {
  checkFields: string[];
  originalData: {}[];
  changedData: {}[];
  checkboxState: { empty: boolean; invalid: boolean }[];
};

const SessionPreview: React.FC<ReviewedData> = (props) => {
  const { session } = useParams<SessionMatchParams>();
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;
  const theme = useAppTheme();

  return (
    <>
      {props.changedData.length && props.originalData.length ? (
        <>
          <Typography variant="h4" sx={theme.text.title}>
            {`${$t({ id: 'domain.sessionReview' })} - ${session}`}
          </Typography>
          <Typography variant="subtitle1" sx={theme.text.subtitle}>
            {`${$t({ id: 'preview' })}`}
          </Typography>

          <TableContainer component={Paper} className={classes.container}>
            <Table sx={{ minWidth: 650 }} stickyHeader aria-label="preview-table">
              <TableHead>
                <TableRow>
                  <TableCell key={'address'} align="left" className={classes.headerCell}>
                    {$t({ id: 'sessionReview.address' })}
                  </TableCell>
                  {props.checkFields.map((field: string) => {
                    return (
                      <TableCell key={field} align="left" className={classes.headerCell}>
                        {field}
                      </TableCell>
                    );
                  })}
                  <TableCell key={'checkboxFlags'} align="left" className={classes.headerCell}>
                    {$t({ id: 'flags' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.changedData.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left" key={`address${props.originalData[index]['address']}`}>
                      {props.originalData[index]['address']}
                    </TableCell>

                    {props.originalData[index] &&
                      props.checkFields.map((field, fieldIndex) => (
                        <TableCell key={fieldIndex} align="left">
                          {props.changedData[index][field] !== undefined ? (
                            <Typography className={classes.alteredValue}>
                              {props.changedData[index][field]}
                            </Typography>
                          ) : props.originalData[index][field] === '' ? (
                            ''
                          ) : (
                            props.originalData[index][field]
                          )}
                        </TableCell>
                      ))}

                    <TableCell key={`checkboxState${index}`} align="left">
                      {props.checkboxState[index].empty == true
                        ? $t({ id: 'empty' })
                        : props.checkboxState[index].invalid == true
                          ? $t({ id: 'invalid' })
                          : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}
    </>
  );
};

export default SessionPreview;
