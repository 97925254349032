import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel
} from '@mui/material';
import { DomainSettings } from 'flyid-core/dist/Database/Models';
import { AcquisitionDataFlags } from 'flyid-core/dist/Database/Models/AcquisitionData';
import { parseEpochFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { getDeflated } from 'src/util/helpers/files';
import { fetchAndShowStoragePictures } from '../../../redux/actions/userActions';
import LoadingCircle from '../../widgets/LoadingCircle';
import SessionPreview from './SessionPreview';
import useFirebaseImage from 'src/hooks/useFirebaseImage';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0
  },
  subtitleContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  singleButton: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  textField: {
    width: '10vw'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  image: {
    height: 200,
    width: 400
  },
  headerCell: {
    fontWeight: 'bold'
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  marginRight: {
    marginRight: theme.spacing(1)
  },
  disabledField: {
    backgroundColor: '#F5F5F5'
  }
}));

type Props = {
  reviewableAddresses: {}[];
  totalAddresses: {}[];
  checkFields: { fieldName: string; isRequired: boolean }[];
  resultFieldNull: string;
  settings: DomainSettings | undefined;
};

type ReviewState = {
  page: number;
  total: number;
};

enum CheckboxType {
  INVALID = 'invalid',
  EMPTY = 'empty'
}

const SessionDataReviewer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const $t = intl.$t;
  const history = useHistory();
  const theme = useAppTheme();

  const { domain, session } = useParams<SessionMatchParams>();
  const { profile, targetCompany, userData } = useAppSelector((s) => ({
    profile: selectCurrentUserProfile(s),
    targetCompany: selectTargetCompany(s),
    userData: s.user
  }));

  const [reviewState, setReviewState] = useStateReducer<ReviewState>({ page: 1, total: 0 });
  const [changedData, setChangedData] = useState<{}[]>([]);

  const totalAddresses = props.totalAddresses;
  const checkFields: string[] = props.checkFields.map((fields) => {
    return fields.fieldName;
  });
  const isFieldRequired: boolean[] = props.checkFields.map((fields) => {
    return fields.isRequired;
  });
  const initialRowData = props.reviewableAddresses
    ? props.reviewableAddresses[reviewState.page - 1]
    : null;

  const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    // When textfield value is changed, seach for the specific position to changed the object value
    const newData = changedData.map((obj, index) => {
      if (index === reviewState.page - 1) {
        return { ...obj, [name]: value };
      }
      return obj;
    });
    setChangedData(newData);
  };

  // Handling Session Review Checkboxes
  const setEmptyAddress = (bool: boolean | undefined, reset: boolean | undefined) => {
    let resetedData: {}[] = [];

    if (reset) {
      resetedData = changedData.map((obj, index) => {
        if (index === reviewState.page - 1) {
          return {};
        }
        return obj;
      });
    } else {
      resetedData = changedData;
    }

    if (bool == true) {
      const newData = resetedData.map((obj, index) => {
        if (index === reviewState.page - 1) {
          return {
            ...obj,
            ...checkFields.reduce((acc, field) => {
              acc[field] = props.resultFieldNull;
              return acc;
            }, {})
          };
        }
        return obj;
      });
      setChangedData(newData);
    } else {
      const newData = resetedData.map((obj, index) => {
        if (index === reviewState.page - 1) {
          return {};
        }
        return obj;
      });
      setChangedData(newData);
    }
  };

  const [checkboxState, setCheckboxState] = useState<{ empty: boolean; invalid: boolean }[]>([]);

  const handleCheckBox = (flag: string) => {
    let bool: boolean | undefined;
    let reset: boolean | undefined;
    const page = reviewState.page - 1;

    const newData = checkboxState.map((value, index) => {
      if (index == page) {
        if (flag == CheckboxType.EMPTY) {
          bool = !value.empty;
          if (value.invalid == true) {
            reset = true;
            return { empty: !value.empty, invalid: !value.invalid };
          } else {
            reset = false;
            return { empty: !value.empty, invalid: value.invalid };
          }
        } else if (flag == CheckboxType.INVALID) {
          bool = !value.invalid;
          if (value.empty == true) {
            reset = true;
            return { empty: !value.empty, invalid: !value.invalid };
          } else {
            reset = false;
            return { empty: value.empty, invalid: !value.invalid };
          }
        }
      }
      return value;
    });

    setCheckboxState(newData);
    setEmptyAddress(bool, reset);
  };

  // Returns the table that contains the original values of an specific session address
  // It also has an input for each check field with the with the possibility of updating the value
  function ReviewTable(initialRowValue: {} | null) {
    return (
      <>
        {initialRowValue ? (
          <>
            <Box className={classes.checkBoxContainer}>
              <Typography className={classes.marginRight}>
                {$t({ id: 'sessionReview.checkboxes' })}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${initialRowValue['address']}empty`}
                    onChange={() => handleCheckBox(CheckboxType.EMPTY)}
                    checked={checkboxState[reviewState.page - 1].empty}
                  />
                }
                label={$t({ id: 'empty' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${initialRowValue['address']}invalid`}
                    onChange={() => handleCheckBox(CheckboxType.INVALID)}
                    checked={checkboxState[reviewState.page - 1].invalid}
                  />
                }
                label={$t({ id: 'invalid' })}
              />
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="sessionReviewTable">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerCell}>
                      {$t({ id: 'sessionReview.fieldName' })}
                    </TableCell>
                    <TableCell align="right" className={classes.headerCell}>
                      {$t({ id: 'sessionReview.acquiredValue' })}
                    </TableCell>
                    <TableCell align="right" className={classes.headerCell}>
                      {$t({ id: 'sessionReview.reviewedValue' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {checkFields.map((field, index) => (
                    <TableRow
                      key={field}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      className={
                        checkboxState[reviewState.page - 1].empty ||
                        checkboxState[reviewState.page - 1].invalid
                          ? classes.disabledField
                          : ''
                      }
                    >
                      <TableCell component="th" scope="row">
                        {field}
                      </TableCell>
                      <TableCell align="right">{initialRowValue[field]}</TableCell>
                      <TableCell align="right">
                        <TextField
                          id={`${initialRowValue['address']}${field}`}
                          name={field}
                          placeholder={
                            changedData[reviewState.page - 1]?.[field] ?? initialRowValue[field]
                          }
                          className={classes.textField}
                          onChange={handleTextfieldChange}
                          value={changedData[reviewState.page - 1]?.[field] ?? ''}
                          variant="outlined"
                          size="small"
                          disabled={
                            checkboxState[reviewState.page - 1].empty ||
                            checkboxState[reviewState.page - 1].invalid
                          }
                          required={isFieldRequired[reviewState.page - 1]}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : null}
      </>
    );
  }

  const checkRequirement = () => {
    const page = reviewState.page - 1;
    const verification = checkFields.map((field, index) => {
      if (isFieldRequired[index] == true && changedData[page][field] == undefined) {
        return false;
      } else {
        return true;
      }
    });

    return verification.includes(false) ? false : true;
  };

  const handleNextPage = () => {
    const verification = checkRequirement();

    if (verification == true) {
      if (reviewState.page < reviewState.total + 1) {
        setReviewState({ page: reviewState.page + 1 });
      }
    } else {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'sessionReview.fieldRequirement' }),
            severity: 'error',
            show: true
          }
        })
      );
    }
  };

  const handlePreviousPage = () => {
    if (reviewState.page > 1) {
      setReviewState({ page: reviewState.page - 1 });
    }
  };

  // If the user clicks on the picture, it will trigger react viewer for zoom and display options
  const showPicture = () => {
    if (props.settings && profile && initialRowData && targetCompany) {
      const filenames = initialRowData[AcquisitionDataFlags.PictureFilenames] as string | string[];

      dispatch(
        fetchAndShowStoragePictures({
          company: targetCompany,
          domain,
          address: initialRowData['address'] as string,
          checkedAtMs: parseEpochFromTimestamp(initialRowData['checkedAt'] as TimestampLike),
          pictureFilenames: Array.isArray(filenames) ? filenames : [filenames],
          addressField: props.settings?.fieldSettings.baseFields.address,
          intl
        })
      );
    }
  };

  // Before submiting the data, we verify if there is any field that was not changed
  // If so, the original value of that field is added to the object
  const handleSubmit = () => {
    let count = 0;
    totalAddresses.forEach((finalData: {}, idx: number) => {
      props.reviewableAddresses.forEach((originalData: {}, index: number) => {
        if (finalData['address'] == originalData['address']) {
          Object.entries(changedData[index]).forEach(([key, value]) => {
            finalData[key] = value;
          });

          finalData['checkedAt'] = Date.now();
          finalData['employeeName'] = `${profile.firstName} ${profile.lastName}`;
          finalData['employeeId'] = profile.employeeId;
          finalData['acquisition_flag_invalid'] = checkboxState[count].invalid;

          count += 1;
        }
      });
    });

    const finalData: { string: {}[] } | {} = {};

    totalAddresses.forEach((address) => {
      const obj = [{ ...address }];
      finalData[address['address']] = obj;
    });

    showDialogConfirmation(finalData);
  };

  // This function show a confirmation dialog, If the user confirms the action, it will trigger the session update function
  const showDialogConfirmation = async (data: { string: {}[] } | {}) => {
    await getDeflated(JSON.stringify(data)).then((deflatedAddresses) => {
      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: 'sessionReview.submitReviewTitle' }),
            message: $t({ id: 'sessionReview.submitReviewMsg' }),
            show: true
          }).setConfirmAction(Actions.UPDATE_SESSION, {
            company: targetCompany!,
            domain: domain,
            session: session,
            userName: `${userData.profile.firstName} ${userData.profile.lastName}`,
            addresses: deflatedAddresses,
            redirectUser: () => history.push(`/domains/${domain}/`)
          })
        })
      );
    });
  };

  function FooterButtons() {
    return (
      <Box className={reviewState.page == 1 ? classes.singleButton : classes.buttonContainer}>
        {reviewState.page == 1 ? null : (
          <Button variant="contained" size="small" onClick={handlePreviousPage}>
            {$t({ id: 'previous' })}
          </Button>
        )}

        {reviewState.page == reviewState.total + 1 ? (
          <Button onClick={handleSubmit} variant="contained" size="small">
            {$t({ id: 'sessionReview.finish' })}
          </Button>
        ) : (
          <Button variant="contained" size="small" onClick={handleNextPage}>
            {reviewState.page === reviewState.total ? $t({ id: 'preview' }) : $t({ id: 'next' })}
          </Button>
        )}
      </Box>
    );
  }

  // Fetch the image of an address and set its src and alt to a useState
  const imageFromStorage = useFirebaseImage(
    targetCompany,
    domain,
    initialRowData?.[AcquisitionDataFlags.PictureFilenames] as string
  );

  // Prepare components states on load
  useEffect(() => {
    // For each object inside the array "reviewableAddresses", add an empty object to be populated into "changedData" state
    if (props.reviewableAddresses) {
      setChangedData(props.reviewableAddresses.map(() => ({})));
      setCheckboxState(props.reviewableAddresses.map(() => ({ empty: false, invalid: false })));
    }

    setReviewState({
      page: 1,
      total: props.reviewableAddresses ? props.reviewableAddresses.length : 0
    });
  }, [props.reviewableAddresses]);

  return (
    <>
      {props.reviewableAddresses && props.checkFields ? (
        <Box className={classes.container}>
          <Box>
            {reviewState.page == reviewState.total + 1 ? (
              changedData.length ? (
                <>
                  <SessionPreview
                    originalData={props.reviewableAddresses}
                    changedData={changedData}
                    checkFields={checkFields}
                    checkboxState={checkboxState}
                  />
                  {FooterButtons()}
                </>
              ) : null
            ) : (
              <>
                <Box>
                  <Typography variant="h4" sx={theme.text.title}>
                    {`${$t({ id: 'domain.sessionReview' })} - ${session}`}
                  </Typography>
                </Box>
                <Box className={classes.subtitleContainer}>
                  <Typography variant="subtitle1" sx={theme.text.subtitle}>
                    {`${initialRowData ? `${$t({ id: 'sessionReview.address' })}: ${initialRowData['address']}` : null}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={theme.text.subtitle}>
                    {$t({ id: 'sessionReview.page' })} {reviewState.page} / {reviewState.total}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={theme.text.subtitle}>
                  {$t({ id: 'sessionReview.description' })}
                </Typography>
                <Box className={classes.imageContainer}>
                  {imageFromStorage.loading ? (
                    <LoadingCircle />
                  ) : (
                    <Button onClick={showPicture}>
                      <Box
                        component="img"
                        className={classes.image}
                        alt={imageFromStorage.image?.alt}
                        src={imageFromStorage.image?.src}
                      />
                    </Button>
                  )}
                </Box>
                {ReviewTable(initialRowData)}
                {FooterButtons()}
              </>
            )}
          </Box>
        </Box>
      ) : (
        <LoadingCircle />
      )}
    </>
  );
};

export default SessionDataReviewer;
